@use "@nutt/ui/theme";
@use "@nutt/ui/fonts";
@use "@nutt/ui/base";
@use "sass:color";

// Colors
$colors: (
  primary: #00b0ea,
  primary-light: color.adjust(#00b0ea, $lightness: 52%),
  base: color.adjust(#17253f, $lightness: 10%),
  base-dark: #17253f,
  base-light: color.adjust(#17253f, $lightness: 72%),
  error: #e73277,
  error-light: color.adjust(#e73277, $lightness: 40%),
  info: #00b0ea,
  info-light: color.adjust(#00b0ea, $lightness: 50%),
  success: color.adjust(#afc90c, $lightness: -5%),
  success-light: color.adjust(#afc90c, $lightness: 50%),
  warning: color.adjust(#fecc1c, $lightness: -12%),
  warning-light: color.adjust(#fecc1c, $lightness: 39%),
  special: #e83278,
  special-light: color.adjust(#e83278, $lightness: 40%),
);
@include theme.colors($colors);

// Fonts
$fonts: (
  title: "Klavika",
  text: "Klavika",
);
@include theme.fonts($fonts);

// Icons
@include theme.icons();

// Client Fonts
@font-face {
  font-family: "Klavika";
  font-weight: 200;
  src: url("/assets/fonts/Klavika-Light.otf");
}

@font-face {
  font-family: "Klavika";
  font-weight: 400;
  src: url("/assets/fonts/Klavika-Regular.otf");
}

@font-face {
  font-family: "Klavika";
  font-weight: 500;
  src: url("/assets/fonts/Klavika-Medium.otf");
}

@font-face {
  font-family: "Klavika";
  font-weight: 600;
  src: url("/assets/fonts/Klavika-Bold.otf");
}
