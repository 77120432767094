@use "sass:color";

// All the customizable base colors
$primary: var(--theme-color-primary, #30b5b0);
$primary-light: var(--theme-color-primary-light, color.adjust(#30b5b0, $lightness: 52%));

$base: var(--theme-color-base, #322d91);
$base-light: var(--theme-color-base-light, color.adjust(#322d91, $lightness: 57%));
$base-dark: var(--theme-color-base-dark, color.adjust(#322d91, $lightness: -14%));

$success: var(--theme-color-success, color.adjust(#90af24, $lightness: -7%));
$success-light: var(--theme-color-success-light, color.adjust(#90af24, $lightness: 50%));

$error: var(--theme-color-error, #d62d2b);
$error-light: var(--theme-color-error-light, color.adjust(#d62d2b, $lightness: 46%));

$info: var(--theme-color-info, color.adjust(#3ca4e7, $lightness: -8%));
$info-light: var(--theme-color-info-light, color.adjust(#3ca4e7, $lightness: 38%));

$warning: var(--theme-color-warning, #f76311);
$warning-light: var(--theme-color-warning-light, color.adjust(#f76311, $lightness: 44%));

$special: var(--theme-color-special, color.adjust(#7d3184, $lightness: 5%));
$special-light: var(--theme-color-special-light, color.adjust(#7d3184, $lightness: 58%));

$skills-current: var(--theme-color-skills-current, $primary);
$skills-job: var(--theme-color-skills-job, $warning);
$skills-ambition: var(--theme-color-skills-ambition, $base);

$white: white;
$black: #121628;

$gray-10: #f7f7fa;
$gray-20: #eeeff4;
$gray-30: #dddee3;
$gray-40: #bdbec3;
$gray-50: #87888d;
$gray-60: #77787d;
$gray-70: #67686d;
$gray-80: #47484d;
$gray-90: #27282d;

$shadow: rgba($black, 0.1);
$shadow-dark: rgba($black, 0.2);

$scroll-background: transparent;
$scroll-foreground: rgba(black, 0.25);
