@use "@nutt/ui/colors";

@import "~@angular/cdk/overlay-prebuilt.css";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: colors.$white;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  background-color: colors.$gray-10;
  border: 1px dotted colors.$gray-30;
  > * {
    visibility: hidden;
  }
}

.cdk-drag-preview .cdk-drag {
  transform: translateX(4rem);
}
