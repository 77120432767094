@use "sass:color";

@mixin colors($colors: ()) {
  :root {
    @each $key, $value in $colors {
      --theme-color-#{$key}: #{$value};
    }
  }
}

@mixin fonts($fonts: ()) {
  :root {
    @each $key, $value in $fonts {
      --theme-font-#{$key}: #{$value};
    }
  }

  body {
    font-family: var(--theme-font-text), sans-serif;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: var(--theme-font-title), serif;
  }
  h5,
  h6 {
    font-family: var(--theme-font-text), sans-serif;
  }
}

@mixin icons {
  @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");
}
